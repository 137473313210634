import { ReactNode } from 'react';
import { HorizontalDivider } from '../../../../components/Divider';
import ImageDescriptionBox, {
  ImageDescriptionBoxProps,
} from '../../../../components/ImageDescriptionBox';
import { Namsan } from '../../../../components/Typography';

interface DietImageDescriptionProps extends ImageDescriptionBoxProps {
  order: number;
  title: ReactNode;
}

const DietImageDescription = ({
  src,
  alt,
  description,
  containerStyle,
  imageStyle,
  descriptionStyle,
  order,
  title,
}: DietImageDescriptionProps) => {
  const orderText = order.toString().padStart(2, '0');
  return (
    <ImageDescriptionBox
      src={src}
      alt={alt}
      description={
        <>
          <Namsan
            style={{
              display: 'block',
              color: '#A9A9A9',
              marginBottom: '15px',
            }}
          >
            여성보약 클리닉 차별점 {orderText}
          </Namsan>
          <Namsan
            style={{
              fontSize: '28px',
              fontWeight: 500,
              lineHeight: '40px',
            }}
            $isPrimary
          >
            {title}
          </Namsan>
          <HorizontalDivider
            style={{
              margin: '50px 0',
            }}
          />
          {description}
        </>
      }
      containerStyle={{
        gap: '100px',
        ...containerStyle,
      }}
      imageStyle={imageStyle}
      descriptionStyle={{
        display: 'flex',
        flexDirection: 'column',
        lineHeight: '32px',
        ...descriptionStyle,
      }}
    />
  );
};

export default DietImageDescription;
