import React from 'react';

interface CustomImageDescriptionBoxProps {
  containerStyle?: React.CSSProperties;
  imageStyle?: React.CSSProperties;
  src?: string;
  components?: React.FC[];  // 여러 컴포넌트를 받는 형태로 변경
}

const CustomImageDescriptionBox: React.FC<CustomImageDescriptionBoxProps> = ({
  containerStyle,
  imageStyle,
  src,
  components,
}) => {
  return (
    <div style={containerStyle}>
      {src && (
        <img src={src} style={imageStyle} alt="Custom" />
      )}
      {components && components.length > 0 && (
        <div>
          {components.map((Component, index) => (
            <Component key={index} />
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomImageDescriptionBox;
