import { Route, Routes, useLocation } from 'react-router-dom';
import HomePage from './pages/Home';
import RootLayout from './layouts/RootLayout';
import AboutDoctorPage from './pages/About/Doctor';
import AboutDifferencePage from './pages/About/Difference';
import SpineAcupuncturePage from './pages/Spine/Acupuncture';
import SpineDrxPage from './pages/Spine/Drx';
import SpineTherapyPage from './pages/Spine/Therapy';
import MedicineCustomizedPage from './pages/Medicine/Customized';
import MedicineDietPage from './pages/Medicine/Diet';
import MedicineGongjindanPage from './pages/Medicine/Gongjindan';
import MedicineGyeongokgoPage from './pages/Medicine/Gyeongokgo';
import MedicineWomanPage from './pages/Medicine/Woman';
import MedicineHealthInsurancePage from './pages/Medicine/HealthInsurance';
import HospitalizationPage from './pages/Hospitalization';
import CasePage from './pages/Case';
import DietCasePage from './pages/Medicine/Diet/DietCase';
import { useEffect } from 'react';
import LoginPage from './pages/Auth/Login';
import MyPage from './pages/Auth/My';
import Callback from './pages/Callback';
import FindPasswordPage from './pages/Auth/FindPassword';
import DizzyPage from './pages/Dizzy';
import SignupPage from './pages/Auth/Signup';

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      <Route element={<RootLayout />}>
        <Route path="/" element={<HomePage />} />

        {/* 한의원 소개 */}
        <Route path="/about">
          <Route path="doctor" element={<AboutDoctorPage />} />
          <Route path="difference" element={<AboutDifferencePage />} />
        </Route>

        {/* 척추관절 클리닉 */}
        <Route path="/spine">
          <Route path="acupuncture" element={<SpineAcupuncturePage />} />
          <Route path="drx" element={<SpineDrxPage />} />
          <Route path="therapy" element={<SpineTherapyPage />} />
        </Route>

        {/* 한약 클리닉 */}
        <Route path="/medicine">
          <Route path="customized" element={<MedicineCustomizedPage />} />
          <Route path="health-insurance" element={<MedicineHealthInsurancePage />} />
          <Route path="diet" element={<MedicineDietPage />} />
          <Route path="diet/case/:index" element={<DietCasePage />} />
          <Route path="gongjin-dan" element={<MedicineGongjindanPage />} />
          <Route path="gyeongokgo" element={<MedicineGyeongokgoPage />} />
          <Route path="woman" element={<MedicineWomanPage />} />
        </Route>

        {/* 입원 클리닉 */}
        <Route path="/hospitalization" element={<HospitalizationPage />} />

        {/* 치료사례 */}
        <Route path="/case" element={<CasePage />} />

        <Route path="/dizzy" element={<DizzyPage />} />

        {/* 회원 */}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/find-pw" element={<FindPasswordPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/my" element={<MyPage />} />
      </Route>
      <Route path="/callback/:provider" element={<Callback />} />
      <Route path="*" element={<HomePage />} />
    </Routes>
  );
}

export default App;
