// MobileVersion.tsx

import { useState } from 'react';
import ElipseBox from '../../../components/EllipseBox';
import { NotoSerif } from '../../../components/Typography';
import { MobileContainer, MobileSection } from '../../../components/common';
import { diseaseList, mobileDiseaseList } from './data';
import MobileThesesSwiper from './MobileThesesSwiper';  // Swiper 컴포넌트 임포트

const MobileVersion = () => {
  const [selectedCategory, setSelectedCategory] = useState(0); // 선택된 카테고리 상태

  const categoryData = mobileDiseaseList[selectedCategory]; // 선택된 카테고리 데이터

  if (!categoryData) {
    return <div>카테고리가 없습니다.</div>;
  }

  return (
    <>
      <MobileContainer
        style={{
          padding: '0',
          gap: '40px',
        }}
      >
        {/* 카테고리 선택 섹션 */}
        <MobileSection
          style={{
            background: '#222222',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: '10px',
              padding: '0 20px'
            }}
          >
            {diseaseList.map((category, index) => (
              <ElipseBox
                key={category.category}
                style={{
                  flex: 1,
                  cursor: 'pointer',
                  width: 'auto',
                  maxWidth: '240px',
                  minWidth: '140px',
                  padding: '6px 36px',
                  aspectRatio: '140 / 35',
                }}
                variant={selectedCategory === index ? 'contained' : 'outlined'}
                onClick={() => setSelectedCategory(index)}
              >
                <NotoSerif
                  style={{
                    color: 'inherit',
                    fontSize: '14px',
                    lineHeight: '22.4px',
                  }}
                >
                  {category.category}
                </NotoSerif>
              </ElipseBox>
            ))}
          </div>
        </MobileSection>

        {/* 선택된 카테고리 이미지 섹션 */}
        <MobileSection>
          {categoryData.images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`카테고리 ${selectedCategory} - 이미지 ${index + 1}`}
              style={{
                width: '100%',
                height: 'auto',
                objectFit: 'contain',
              }}
            />
          ))}
        </MobileSection>

        {/* Swiper 컴포넌트 렌더링: selectedCategory와 mobileDiseaseList 전달 */}
        <MobileThesesSwiper
          selectedCategory={selectedCategory}
          mobileDiseaseList={mobileDiseaseList}
        />
      </MobileContainer>
    </>
  );
};

export default MobileVersion;
