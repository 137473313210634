import { useState, useEffect } from 'react';  // useState, useEffect를 가져옵니다.
import { motion } from 'framer-motion';
import Card from '../../../components/Card';
import { HorizontalDivider } from '../../../components/Divider';
import { Namsan, NotoSerif } from '../../../components/Typography';
import {
  CustomizedSection,
  GyeongokgoList,
  GyeongokgoEffectParagraph,
  GyeongokgoDifferenceListItem,
} from './styles';
import {
  effectsList,
  newEffectsList
} from './data';

const PregnancyPreparation = () => {
  const [selectedCategory, setSelectedCategory] = useState<'임신준비' | '산후보약'>('임신준비');

  // 카테고리가 선택되었는지 여부 확인
  const checkSelectedCategory = () => {
    const categoryElement = document.querySelector('.sc-eMHqlA');
    if (categoryElement && categoryElement.classList.contains('ifneLo')) {
      // '임신준비' 카테고리가 선택되었을 때
      setSelectedCategory('임신준비');
    } else if (categoryElement && categoryElement.classList.contains('hUhLAo')) {
      // '산후보약' 카테고리가 선택되었을 때
      setSelectedCategory('산후보약');
    }
  };

  // 카테고리 상태를 추적하기 위해 useEffect 사용
  useEffect(() => {
    checkSelectedCategory();
  }, []); // 최초 렌더링 시 한번만 실행

  // 선택된 카테고리에 맞는 리스트 선택
  const currentEffectsList = selectedCategory === '임신준비' ? effectsList : newEffectsList;

  return (
    <CustomizedSection>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{
          opacity: 1,
          y: 0,
        }}
        transition={{
          ease: 'easeInOut',
          duration: 1.3,
          y: {
            duration: 0.3,
          },
        }}
        viewport={{
          once: true,
        }}
      >
        <Card
          title="365일 척추관절 중점진료 에스본한의원"
          paragraph={
            <>{selectedCategory === '임신준비' ? '임신준비보약의 장점' : '산후보약의 장점'}</>
          }
          position="center"
          containerStyle={{
            marginTop: '200px',
            marginBottom: '50px',
          }}
        />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{
          opacity: 1,
          y: 0,
        }}
        transition={{
          ease: 'easeInOut',
          duration: 1.3,
          delay: 0.3,
          y: {
            duration: 0.3,
          },
        }}
        viewport={{
          once: true,
        }}
      />

      {/* 선택된 카테고리에 맞는 effectsList만 출력 */}
      <GyeongokgoList
        style={{
          marginTop: '60px',
        }}
      >
        {currentEffectsList.map((effect, index) => (
          <GyeongokgoDifferenceListItem key={index}>
            <NotoSerif
              style={{
                color: '#D2A780',
                fontWeight: 500,
              }}
            >
              {effect.title}
            </NotoSerif>
            <Namsan
              size="small"
              style={{
                fontWeight: 300,
                color: '#A9A9A9',
              }}
            />
            <HorizontalDivider
              style={{
                width: '45px',
                height: '2px',
                alignSelf: 'center',
                backgroundColor: '#D2A780',
                margin: '36px 0',
              }}
            />
            <GyeongokgoEffectParagraph>
              <Namsan
                as="p"
                style={{
                  lineHeight: '33px',
                  color: '#d9d9d9',
                  letterSpacing: '-0.03em',
                }}
              >
                {effect.paragraph}
              </Namsan>
            </GyeongokgoEffectParagraph>
          </GyeongokgoDifferenceListItem>
        ))}
      </GyeongokgoList>
    </CustomizedSection>
  );
};

export default PregnancyPreparation;
