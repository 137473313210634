import { useState } from 'react';
import Card from '../../../components/Card';
import { HorizontalDivider } from '../../../components/Divider';
import ElipseBox from '../../../components/EllipseBox';
import CustomImageDescriptionBox from './CustomImageDescriptionBox';
import PageTitle from '../../../components/PageTitle';
import { Namsan, NotoSerif, Pretendard, Roboto } from '../../../components/Typography';
import DietImageDescription from './DietImageDescription';
import {
  CustomizedContainer,
  CustomizedSection,
  DiagnosisList,
  GyeongokgoList,
  GyeongokgoEffectParagraph,
  GyeongokgoDifferenceListItem,
  GyeongokgoMobileList,
} from './styles';
import WomanSwiper from './WomanSwiper';
import { Helmet } from 'react-helmet';
import MobileThesesSwiper from './MobileThesesSwiper';
import { MobileContainer, MobileSection } from '../../../components/common';
import {
  diagnosisList,
  diseaseCategories,
  diseaseList,
  effectsList,
  medicineInfo,
  mobileImageList,
} from './data';
import useWindowSize from '../../../hooks/useWindowSize';
import { motion } from 'framer-motion';
import MobileVersion from './MobileVersion'

const MedicineWomanPage = () => {
  const [selectedCategory, setSelectedCategory] = useState(0);

  const { width } = useWindowSize();
  if ((width || window.innerWidth) < 1080) {
    return (
      <>
        <Helmet>
          <title>에스본한의원 | 여성보약</title>
        </Helmet>
        <PageTitle title="여성보약" bgImage="medicine" />

        <MobileContainer>
          
          <MobileSection>
            {mobileImageList[0].arr.map((image) => (
              <img
                style={{
                  width: '100%',
                }}
                key={image}
                src={`/responsive/medicine/woman/${image}`}
                alt=""
              />
            ))}
          <MobileVersion />
          </MobileSection>

          <MobileSection>
            {mobileImageList[1].arr.map((image) => (
              <img
                style={{
                  width: '100%',
                }}
                key={image}
                src={`/responsive/medicine/woman/${image}`}
                alt=""
              />
            ))}
          </MobileSection>

          <MobileSection>
            {mobileImageList[2].arr.map((image) => (
              <img
                style={{
                  width: '100%',
                }}
                key={image}
                src={`/responsive/medicine/woman/${image}`}
                alt=""
              />
            ))}
          </MobileSection>
        </MobileContainer>
      </>
    );
  }
  return (
    <>
      <Helmet>
        <title>에스본한의원 | 여성보약</title>
      </Helmet>
      <PageTitle title="여성보약" bgImage="medicine" />
      <CustomizedContainer>
        <CustomizedSection>
          <motion.div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              title="365일 척추관절 중점진료 에스본한의원"
              paragraph={
                <>
                  여성건강의 근본을
                  <br />
                  개선하는 한방치료법
                </>
              }
              position="center"
              containerStyle={{
                marginBottom: '30px',
              }}
            />
            <Namsan
              style={{
                textAlign: 'center',
                lineHeight: '33px',
                color: '#d9d9d9',
              }}
            >
              환자의 체질에 맞는 약재를 사용하여
              <br />
              임신준비 및 산후조리를 돕습니다
            </Namsan>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignSelf: 'stretch',
                gap: '20px',
                marginTop: '80px',
              }}
              >
              {diseaseCategories.map((category, index) => (
                <ElipseBox
                key={category}
                style={{
                  flex: 1,
                  cursor: 'pointer',
                  maxWidth: '280px',
                  }}
                  variant={selectedCategory === index ? 'contained' : 'outlined'}
                  onClick={() => {
                    setSelectedCategory(index);
                  }}
                >
                  <NotoSerif
                    style={{
                      color: 'inherit',
                    }}
                    size="large"
                  >
                    {category}
                  </NotoSerif>
                </ElipseBox>
              ))}
            </div>
            
            <CustomImageDescriptionBox
                containerStyle={{
                  marginTop: '94px',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
                imageStyle={{
                  width: '810px',
                  height: '720px',
                  maxWidth: '810px',
                  maxHeight: '710px',
                  objectFit: 'contain',
                  margin: '0 auto'
                }}
                src={diseaseList[selectedCategory].img}
                components={diseaseList[selectedCategory]?.components}
              />
          </motion.div>
        </CustomizedSection>

        <CustomizedSection
          style={{
            gap: '100px',
            width: '100%',
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              title="365일 척추관절 중점진료 에스본한의원"
              paragraph={
                <>
                  에스본한의원의
                  <br />체계적인 검진 과정
                </>
              }
              position="center"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <DietImageDescription
              src="/images/medicine/woman/image2.png"
              alt="첫번째 차별점, 초음파 진단"
              order={1}
              title={
                <Pretendard
                  style={{
                    color: '#D2A780',
                    fontSize: '28px',
                    fontWeight: '500',
                  }}
                >
                  초음파 진단
                </Pretendard>
              }
              descriptionStyle={{
                marginTop: '100px',
              }}
              description={
                <Namsan
                  style={{
                    color: '#d9d9d9',
                  }}
                >
                  초음파 진단을 통해 자궁 및 난소의 형태학적 변화와 자궁 내막의
                  <br />
                  두께, 배란 시기의 파악, 난소의 기능 등을 파악합니다.
                  <br />
                  <br />
                  이를 통해 최적화된 건강상태를 파악할 수 있습니다.
                </Namsan>
              }
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <DietImageDescription
              src="/images/medicine/woman/image3.png"
              alt="두번째 차별점, 간수치 검사"
              order={2}
              title={
                <Pretendard
                  style={{
                    color: '#D2A780',
                    fontSize: '28px',
                    fontWeight: '500',
                  }}
                >
                  간수치 검사
                </Pretendard>
              }
              descriptionStyle={{
                marginTop: '100px',
              }}
              description={
                <Namsan
                  style={{
                    color: '#d9d9d9',
                  }}
                >
                  간의 전반적인 상태를 확인하고, 임신 준비 산모에게 맞는 한약재
                  <br />
                  를 선별하여 안전하게 한약을 조제합니다.
                  <br />
                  <br />
                  임신준비 한약의 흡수율과 대사율을 높이고 간손상을 
                  <br />
                  예방할 수 있습니다.
                </Namsan>
              }
            />
          </motion.div>
        </CustomizedSection>
        <CustomizedSection
          style={{
            alignItems: 'stretch',
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              title="365일 척추관절 중점진료 에스본한의원"
              paragraph={
                <>
                  식품의약품 안전처 연구원
                  <br />
                  출신의 체계적인 맞춤한약 처방
                </>
              }
              position="center"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <DiagnosisList
              style={{
                gridColumnGap: 0,
                margin: '50px auto 0',
                letterSpacing: '-0.02em',
              }}
            >
              {diagnosisList.map((diagnosis) => (
                <li
                  key={diagnosis.title}
                  style={{
                    width: '100%',
                  }}
                >
                  <img
                    style={{
                      display: 'block',
                      maxWidth: '300px',
                      width: '100%',
                      margin: '0 auto',
                    }}
                    src={diagnosis.img}
                    alt={diagnosis.title}
                  />
                  <Card
                    containerStyle={{
                      flexDirection: 'column-reverse',
                      alignItems: 'center',
                    }}
                    titleStyle={{
                      color: '#D9D9D9',
                      textAlign: 'center',
                    }}
                    paragraphSize="large"
                    paragraphStyle={{
                      color: '#D2A780',
                    }}
                    paragraph={diagnosis.title}
                  />
                </li>
              ))}
            </DiagnosisList>
          </motion.div>
        </CustomizedSection>
      </CustomizedContainer>
    </>
  );
};

export default MedicineWomanPage;
