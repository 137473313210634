import styled from 'styled-components';
import { Section, Container, FullWidthSection } from '../../../components/common';
import List from '../../../components/List';
import { Namsan } from '../../../components/Typography';

export const GyeongokgoContainer = styled(Container)`
  gap: 200px;
  align-items: center;
`;

export const GyeongokgoSection = styled(Section)`
  margin: unset;
  width: 100%;
`;

export const ImageContainer = styled.div`
  max-width: 1180px;
  max-height: 500px;
  aspect-ratio: 1180 / 500;
  background-image: url(/images/medicine/gyeongokgo/image1.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: '50px';
  display: flex;
  justify-content: end;
  position: relative;

  & .thesis-wrapper {
    z-index: 10;
    width: 62%;
    height: 100%;
    display: flex;
    align-items: center;
    & img {
      position: relative;
      height: calc(100% - 100px);
      transform: translateX(-50%);
    }
    & > div {
      transform: translateX(-20%);
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 62%;
    height: 100%;
    background-color: #826144bf;
  }
`;

export const FullWidthGyeongokgoSection = styled(FullWidthSection)`
  background-color: #fafaf8;
  height: auto;
`;

export const GyeongokgoList = styled(List)`
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 40px;
  & li {
    flex-direction: column;
    align-items: start;
    border: 1px solid #5d5d5d;
  }
`;

export const GyeongokgoDifferenceList = styled.ol`
  list-style: decimal;
  margin-inline-start: 1rem;
  & li::marker {
    color: #d9d9d9;
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
  }
`;

export const GyeongokgoEffectParagraph = styled.div`
  overflow: hidden;

  ${Namsan} {
    transition: all 1s;
    transform: translateY(-100px);
    opacity: 0;
  }
`;

export const GyeongokgoDifferenceListItem = styled.li`
  padding: 37px 16px 27px;

  &:hover {
    & ${GyeongokgoEffectParagraph} ${Namsan} {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

export const GyeongokgoMobileList = styled(List)`
  grid-column-gap: 10px;
  grid-row-gap: 40px;
  & li {
    flex-direction: column;
  }
`;

export const IngredientCard = ({ imageSrc, title, description }: { imageSrc: string, title: string, description: string }) => (
  <div style={{ width: 'calc(25% - 15px)', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <img style={{ width: '280px' }} src={imageSrc} alt={title} />
    <b style={{ fontFamily: 'Noto Serif KR', fontSize: '28px', lineHeight: '44.8px', fontWeight: '500', letterSpacing: '-0.02em', textAlign: 'center', color: '#D2A780', marginTop: '18px', marginBottom: '17px' }}>
      {title}
    </b>
    <p style={{ fontFamily: 'Pretendard', fontSize: '20px', fontWeight: '200', lineHeight: '28px', letterSpacing: '-0.02em', textAlign: 'center', color: '#d9d9d9' }}>
      {description}
    </p>
  </div>
);
