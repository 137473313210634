import { NavLink, useNavigate } from 'react-router-dom';
import {
  Logo,
  NavMenu,
  NavContainer,
  MenuIcon,
  NavbarSubMenu,
  NavListItem,
  NavUtil,
  NavListWrapper,
  NavSubListItem,
  NavUtilVerticalDivider,
} from './styles';
import { useEffect, useState } from 'react';
import { Pretendard } from '../Typography';
import { authService } from '../../utils/firebase/fbInstance';
import { User } from 'firebase/auth';

const menuData = [
  {
    title: '한의원 소개',
    // href: '/about/doctor',
    subMenu: [
      {
        title: '의료진 소개',
        href: '/about/doctor',
      },
      {
        title: '차별점',
        href: '/about/difference',
      },
    ],
  },
  {
    title: '척추관절 클리닉',
    // href: '/spine/acupuncture',
    subMenu: [
      {
        title: '미네랄 약침',
        href: '/spine/acupuncture',
      },
      {
        title: '추나요법',
        href: '/spine/therapy',
      },
      {
        title: '무중력 감압기',
        href: '/spine/drx',
      },
    ],
  },
  {
    title: '한약 클리닉',
    // href: '/medicine/customized',
    subMenu: [
      {
        title: '맞춤한약',
        href: '/medicine/customized',
      },
      {
        title: '건강보험 첩약',
        href: '/medicine/health-insurance',
      },
      {
        title: '다이어트 한약',
        href: '/medicine/diet',
      },
      {
        title: '공진단',
        href: '/medicine/gongjin-dan',
      },
      {
        title: '경옥고',
        href: '/medicine/gyeongokgo',
      },
      {
        title: '여성보약',
        href: '/medicine/woman',
      },
    ],
  },
  {
    title: '입원 클리닉',
    href: '/hospitalization',
  },
  {
    title: '치료사례',
    href: '/case',
  },
];

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState<number>();
  const [user, setUser] = useState<User | null>();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = authService.onAuthStateChanged((user) => {
      setUser(user);
    });

    return unsubscribe;
  }, []);

  const renderUtils = (user?: User | null) => {
    if (!user) {
      return (
        <>
          <NavLink
            to="/login"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <Pretendard size="small">{user === undefined ? '-' : '로그인'}</Pretendard>
          </NavLink>
          <NavUtilVerticalDivider />
          <NavLink
            to="/signup"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <Pretendard size="small">{user === undefined ? '-' : '회원가입'}</Pretendard>
          </NavLink>
        </>
      );
    }

    return (
      <>
        <NavLink
          to="/"
          onClick={async (e) => {
            e.preventDefault();
            await authService.signOut();
            setIsOpen(false);
            navigate('/login');
          }}
        >
          <Pretendard size="small">로그아웃</Pretendard>
        </NavLink>
        <NavUtilVerticalDivider />
        <NavLink
          to="/my"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <Pretendard size="small">마이페이지</Pretendard>
        </NavLink>
      </>
    );
  };

  return (
    <NavContainer $isOpen={isOpen}>
      <Logo>
        <NavLink to="/">에스본한의원</NavLink>
      </Logo>
      <nav>
        <MenuIcon
          onClick={() => {
            setIsOpen((prev) => !prev);
          }}
          $isOpen={isOpen}
        >
          메뉴 열기
        </MenuIcon>
        <NavListWrapper $isOpen={isOpen}>
          <NavMenu>
            {menuData.map(({ href, title, subMenu }, index) => (
              <NavListItem
                key={title}
                $subMenuCount={subMenu?.length}
                $isSelected={selectedMenu === index}
              >
                <NavLink
                  to={href || '#'}
                  onClick={() => {
                    if (!subMenu) {
                      setIsOpen(false);
                    }
                    setSelectedMenu((prev) => {
                      if (prev === index) return undefined;
                      return index;
                    });
                  }}
                >
                  <Pretendard>{title}</Pretendard>
                </NavLink>
                {subMenu && (
                  <NavbarSubMenu>
                    {subMenu.map(({ title, href }) => (
                      <NavSubListItem
                        key={title}
                        onClick={() => {
                          setIsOpen(false);
                        }}
                      >
                        <NavLink to={href}>
                          {({ isActive }) => (
                            <Pretendard
                              style={{
                                color: isActive ? '#191919' : undefined,
                              }}
                            >
                              {title}
                            </Pretendard>
                          )}
                        </NavLink>
                      </NavSubListItem>
                    ))}
                  </NavbarSubMenu>
                )}
              </NavListItem>
            ))}
          </NavMenu>
          <NavUtil>{renderUtils(user)}</NavUtil>
        </NavListWrapper>
      </nav>
    </NavContainer>
  );
};

export default Navbar;
