import { MobileImageData } from '../../../types/types';

export const effectsList = [
  {
    title: '폐 보호 효과',
    source: '상지대학교 한의과대학 생리학교실 제24권 2호 (2011년 3월)',
    paragraph:
      '경옥고를 복용시킨 실험연구에서 결핵균에 대하여 항결핵 효과가 높으며 고농도(500μg/m\u2113)에서 현저한 내성억제효과가 나타났고, 경옥고와 RFP, CNF혼합하여 투여하였을 때 강력한 항결핵 효과가 나타났다는 보고가 있다.',
  },
  {
    title: '항노화 효과',
    source: '대한한방내과학회지 제43권 3호(2022년 6월)',
    paragraph:
      '노화 척도 점수 감소, 결핵 환자의 Th1 cell 증가 및 Th2 cell 감소, 중의변증 척도 중 阴虚와 气虚 점수 감소 및 痰湿 점수 증가가 확인되었다.',
  },
  {
    title: '운동능력 향상',
    source: '생약학회지(2016년)',
    paragraph:
      '4주차 강제 수영운동에서는 생리식염수 (9.0±4.1 min)와 홍삼 추출물 (15.7±9.5 min) 투여군과 비교하여 경옥고(24.0±12.6 min) 투여군에서 운동지속시간이 유의하게 높게 나타났다.',
  },
  {
    title: '항염증 효과',
    source: '大韓本草學會誌 제32권 제3호(2017년 5월)',
    paragraph:
      '경옥고 처리군에서 NOS-II mRNA 유전자 발현을 분석한 결과, K0 군에서 유의미한 감소(p<0.05)가 확인되었으며, 경옥고 가미방 중에서는 BKF 군(p<0.01)에서 가장 큰 감소를 보였고, 이어 BK(p<0.01), KF(p<0.05 순으로 감소가 나타났다.',
  },
  {
    title: '항암효과',
    source: 'The journal of Korean medical classics, v.24 no.2, 2011년',
    paragraph:
      '항암효과를 가진 漆根과 항균효과가 있는 松根을 첨가한 경옥고를 제조 하여 자궁암 수술 후 항암치료 중인 환자에게 복약시켜 완고한 하복부 통증과 체력저하 증상을 개선시키는 양호한 효과를 얻는 임상사례가 있었다.',
  },
  {
    title: '갱년기 증후군 개선',
    source: '생약학회지 (2020)',
    paragraph:
      '해마에서 estrogen 수용체와 학습, 기억에 중요한 역할을 하는 mBDNF의 발현을 확인한 결과, 난소절제로 인한 mBDNF 발현 감소가 경옥고 200mg/kg, 600mg/kg, 1800mg/kg을 8주간 투여한 그룹에서 회복되는 양상을 보였다.',
  },
];

export const suggestionsList = [
  {
    title: '피부 건강 개선',
    description: (
      <>
        잦은 피부 트러블, 건조함, 또는<br />
        탄력 저하로 고민하시는 분들에게 권해드립니다.
      </>
    ),
    image: '/images/medicine/gyeongokgo/list/image1.png',
  },
  {
    title: '체력 보강 및 기력 회복',
    description: (
      <>
        만성 피로와 무기력함으로<br />
        일상생활이 힘드신 분들께<br />
        권해드립니다.
      </>
    ),
    image: '/images/medicine/gyeongokgo/list/image2.png',
  },
  {
    title: '호흡기 건강 관리',
    description: (
      <>
        기관지염, 천식, 잦은 기침 등<br />
        호흡기 질환으로 고생하시는<br />
        분들에게 권해드립니다.
      </>
    ),
    image: '/images/medicine/gyeongokgo/list/image3.png',
  },
  {
    title: '수면 질 개선',
    description: (
      <>
        불면증이나 얕은 잠으로 인해<br />
        피로가 쌓이시는 분들에게<br />
        권해드립니다.
      </>
    ),
    image: '/images/medicine/gyeongokgo/list/image4.png',
  },
  {
    title: '산후 회복 및 체력 증진',
    description: (
      <>
        산후 기력 회복이<br />
        필요한 산모들에게<br />
        권해드립니다.
      </>
    ),
    image: '/images/medicine/gyeongokgo/list/image5.png',
  },
  {
    title: '위장 건강 강화',
    description: (
      <>
        소화불량, 속쓰림, 식욕 저하 등<br />
        위장 건강에 고민이 있으신<br />
        분들에게 권해드립니다.
      </>
    ),
    image: '/images/medicine/gyeongokgo/list/image6.png',
  },
];

export const mobileImageList: MobileImageData[] = [
  {
    arr: ['1-1.svg'],
  },
  {
    arr: ['2-1.svg'],
  },
  {
    arr: ['3-1.svg'],
  },
  {
    arr: ['4-1.svg', '4-2.svg', '4-3.svg', '4-4.svg', '4-5.svg'],
  },
];
