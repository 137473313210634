import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { CSSProperties, useRef, useState } from 'react';
import { Pagination, Thumbs } from 'swiper/modules';
import CustomPagination from '../../../components/CustomPagination';

interface HomeSwiperProps {
  className?: string;
  style?: CSSProperties;
}

const slidesLength = 6;

const MobileThesesSwiper = ({ className, style }: HomeSwiperProps) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);

  const paginationRef = useRef(null);
  const pagination = {
    el: paginationRef.current,
    clickable: true,
    renderBullet: (index: number, className: string) => {
      return '<span class="' + className + '">' + (index + 1) + '</span>';
    },
  };

  return (
    <div>
      <Swiper
        slidesPerView={'auto'}
        spaceBetween={20}
        loop={true}
        className={className}
        style={{
          width: '100%',
        }}
        modules={[Thumbs]}
        thumbs={{
          swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
        }}
      >
        {Array.from({ length: slidesLength }).map((_, index) => (
          <SwiperSlide
            key={index}
            style={{
              width: '400px',
              height: '337px',
            }}
          >
            <img
              style={{
                width: '100%',
                height: '100%',
              }}
              src={`/responsive/medicine/gyeongokgo/theses/${index + 1}.svg`}
              alt=""
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        slidesPerView={'auto'}
        spaceBetween={20}
        loop={true}
        className={className}
        style={{
          maxWidth: '400px',
          marginTop: '60px',
        }}
        modules={[Thumbs, Pagination]}
        onSwiper={setThumbsSwiper}
        pagination={pagination}
      >
        {Array.from({ length: slidesLength }).map((_, index) => (
          <SwiperSlide key={index}>
            <img
              style={{
                width: '100%',
                height: '100%',
              }}
              src={`/responsive/medicine/gyeongokgo/effects/${index + 1}.svg`}
              alt=""
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <CustomPagination ref={paginationRef} />
    </div>
  );
};

export default MobileThesesSwiper;
