import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { CSSProperties } from 'react';
import { Namsan, NotoSerif, Pretendard } from '../../../components/Typography';

interface GyeongokgoSwiperProps {
  className?: string;
  style?: CSSProperties;
}

const theses = [
  {
    title: '폐 보호 효과',
    source: '상지대학교 한의과대학 생리학교실 제24권 2호 (2011년 3월)',
    content: (
      <>
        "폐 결핵, 폐암 등과 같은<br />
        질병치료 효능이 있다."
      </>
    ),

    image: '/images/medicine/gyeongokgo/theses/image1.png',
  },
  {
    title: '항노화 효과',
    source: '대한한방내과학회지 제43권 3호(2022년 6월)',
    content: (
      <>
        “노화척도 점수는 경옥고 투여군에서<br />
        통계적으로 유의미하게 낮게 측정되었다.”
      </>
    ),
    image: '/images/medicine/gyeongokgo/theses/image2.png',
  },
  {
    title: '운동능력 향상',
    source: '생약학회지(2016년)',
    content: (
      <>
        “경옥고 투여군에서 2주차 악력의 증가와 3주차<br />
        탈진 트레드밀 운동지속시간 증가, 4주차 탈진 수영<br />
        운동지속시간이 증가되었음을 확인하였다.”
      </>
    ),
    image: '/images/medicine/gyeongokgo/theses/image3.png',
  },
  {
    title: '항염증 효과',
    source: '大韓本草學會誌 제32권 제3호(2017년 5월)',
    content: (
      <>
        “경옥고 및 경옥고 가미방에 관한 선행연구로<br />
        항염증 활성, 항산화 활성에 관한 연구결과가<br />
        보고된 바 있다.”
      </>
    ),
    image: '/images/medicine/gyeongokgo/theses/image4.png',
  },
  {
    title: '항암효과',
    source: 'The journal of Korean medical classics, v.24 no.2, 2011년',
    content: (
      <>
        "경옥고는 폐결핵, 폐암, 골수억제 개선 효과<br />
        등과 같은 질병 치료 효능이 있다."
      </>
    ),
    image: '/images/medicine/gyeongokgo/theses/image5.png',
  },
  {
    title: '갱년기 증후군 개선',
    source: '생약학회지 (2020)',
    content: (
      <>
        "갱년기로 인해 감소되었던 인지능력이<br />
        모든 용량에서 유의성 있게 증가되었다."
      </>
    ),
    image: '/images/medicine/gyeongokgo/theses/image6.png',
  },
];

const GyeongokgoSwiper = ({ className, style }: GyeongokgoSwiperProps) => {
  return (
    <Swiper
      slidesPerView={'auto'}
      loop={true}
      modules={[Pagination, Navigation, Autoplay]}
      className={className}
      style={style}
      grabCursor={true}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
    >
      {theses.map((thesis) => (
        <SwiperSlide key={thesis.title}>
          <div
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <div className="thesis-wrapper">
              <img src={thesis.image} alt="" />
              <div>
                <NotoSerif
                  style={{
                    color: '#ffffff',
                    fontWeight: 500,
                    lineHeight: '33px',
                  }}
                >
                  {thesis.title}
                </NotoSerif>
                <Pretendard
                  style={{
                    color: '#ffffff',
                    display: 'block',
                    marginTop: '5px',
                    marginBottom: '30px',
                  }}
                  size="medium"
                >
                  {thesis.source}
                </Pretendard>
                <Namsan
                  style={{
                    color: '#ffffff',
                    lineHeight: '33px',
                  }}
                >
                  {thesis.content}
                </Namsan>
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default GyeongokgoSwiper;
