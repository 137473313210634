import { Component } from 'react';
import { MobileImageData } from '../../../types/types';
import GongjindanSection from './GongjindanSection';
import PregnancyPreparation from './PregnancyPreparation';
import MobileThesesSwiper from './MobileThesesSwiper';
import { MobileThesesSwiperProps } from './MobileThesesSwiper';



interface DiseaseItem {
  category: string;
  img?: string;
  components?: React.FC[];  // component를 배열로 설정
}

// diseaseList에서 component만 사용
export const diseaseList: DiseaseItem[] = [
  {
    category: '임신준비',
    img: '/images/medicine/woman/image1.svg',
    components: [PregnancyPreparation],  // 여러 컴포넌트 배열로 설정
  },
  {
    category: '산후보약',
    components: [GongjindanSection, PregnancyPreparation],  // PregnancyPreparation 추가
  },
];




export const diseaseCategories = diseaseList.map((disease) => disease.category);

export const medicineInfo = [
  '체질에 맞는 약재를 사용합니다.',
  '진단한 날 바로 처방합니다.',
  '나이에 따라 복용량과 방법을 다르게 처방합니다.',
];

export const effectList = [
  '염증 및 부종, 통증 완화',
  '뼈, 인대, 근육 강화',
  '관절 척추에 영양 공급',
  '면역력 및 기력 증진',
];

export const diagnosisList = [
  {
    title: '안전성↑',
    img: '/images/medicine/woman/icon1.png',
  },
  {
    title: '높은 품질',
    img: '/images/medicine/woman/icon2.png',
  },
  {
    title: '전문성',
    img: '/images/medicine/woman/icon3.png',
  },
];

export const mobileImageList: MobileImageData[] = [
  {
    arr: [ '1-1.svg',],
  },
  {
    arr: ['2-1.svg', '2-2.png', '2-3.svg', '2-4.png', '2-5.svg'],
  },
  {
    arr: ['3-1.svg', '3-2.png', '3-3.png', '3-4.png'],
  },
];

export const effectsList = [
  {
    title: '01. 체질에 맞춤처방',
    paragraph:
      '에스본한의원의 임신 준비 한약은 체질을 분석하여, 자궁과 난소의 건강을 최적화하고, 배란 주기를 조절하여 임신 확률을 높일 수 있도록 도와줍니다. 혈액순환이 부족한 경우에는 혈을 보강하고, 기력이 약한 경우에는 기를 보강하는 처방을 통해 체내 환경을 개선합니다.',
  },
  {
    title: '02. 호르몬 균형 조절',
    paragraph:
      '여성 호르몬의 불균형은 난임의 주된 원인입니다. 한약은 한의학적 이론에 기반해 여성 호르몬의 균형을 맞추는 데 도움을 줄 수 있습니다. 자궁 내막을 튼튼하게 하고 배란을 촉진시키는 효과가 있는 약재를 활용하여 호르몬의 안정성을 유지할 수 있습니다.',
  },
  {
    title: '03. 자궁과 난소 기능 강화',
    paragraph:
      '한약은 자궁을 따뜻하게 하고 혈류를 증가시키는 데 도움을 줄 수 있으며, 난소의 기능을 정상화하여 건강한 난자를 생산하도록 돕습니다. 이러한 처방은 자궁 내막이 두꺼워지고, 착상에 유리한 환경을 만드는 데 기여할 수 있습니다.',
  },
  {
    title: '04. 스트레스와 피로 회복',
    paragraph:
      '임신 준비 과정에서 여성은 신체적, 정신적 스트레스에 시달리기 쉽습니다. 한약은 스트레스를 완화하고 기운을 복돋아주는 효능이 있습니다. 신체적인 면역력의 향상 뿐만 아니라, 준비 과정에서 중요한 역할을 합니다. 또한, 과도한 스트레스는 배란에 영향을 미칠 수 있기에 정신적 안정이 필수적입니다.',
  },
  {
    title: '05. 면역력 증진',
    paragraph:
      '한약은 체내의 면역 체계를 강화하는 데 도움을 줍니다. 특히 임신 준비 기간 동안 자주 발생할 수 있는 감기나 기타 질병 예방에 중요한 역할을 하며, 건강한 임신을 위한 신체 상태를 유지하는 데 기여할 수 있습니다.',
  },
  {
    title: '06. 자연적인 치료법',
    paragraph:
      '한약은 천연 약재를 사용하여 몸의 균형을 회복하는 자연적인 방법입니다. 화학적인 약물에 비해 부작용이 적고, 지속적인 관리가 가능하여 안전한 선택이 될 수 있습니다. 체질과 상태에 맞는 처방을 받는 것이 중요합니다.',
  },
];

export const newEffectsList = [
  {
    title: '01. 기혈 보충 및 회복',
    paragraph: '출산 과정에서 산모는 많은 에너지를 소모하게 됩니다. 이때 산후보약은 기와 혈을 보강하는 데 중요한 역할을 합니다. 특히, 출산 후 ‘혈허’ 상태가 되어 있는 경우가 많은데 산후보약은 기혈을 회복시키기 위한 약재들이 포함되어 있습니다. 이는 전반적인 체력 회복을 촉진하고 피로감을 해소하는 데 도움이 됩니다.',
  },
  {
    title: '02. 자궁 회복 및 자궁 내막 회복',
    paragraph: '여성 호르몬 불균형을 해결하고 배란 주기를 조절하여 임신 확률을 높입니다.출산 후 자궁이 원래 상태로 돌아가는 데 시간이 필요합니다. 산후보약은 자궁 수축을 촉진하고, 자궁 내막의 회복에 중요한 역할을 합니다. 이를 통해 자궁의 기능을 정상화하고, 출산 후 발생할 수 있는 자궁 질환을 예방할 수 있습니다.',
  },
  {
    title: '03. 혈액순환 개선 및 어혈 제거',
    paragraph: '출산 후 자궁 내 출혈과 어혈(체내 정체된 혈액)이 발생할 수 있습니다. 산후보약은 어혈을 풀고 혈액순환을 개선하여 자궁 회복을 돕고, 전반적인 몸 상태를 정리하는 데 유효합니다. 이는 복부 팽창이나 부종을 완화하는 데 도움을 줍니다.',
  },
  {
    title: '04. 면역력 강화',
    paragraph: '출산 후 면역 체계가 약화될 가능성이 있습니다. 이때 산후보약은 면역력을 높여 감염에 대한 저항력을 강화합니다. 이는 여성의 신체가 외부 병원균에 대한 방어력을 회복하는 데 중요한 역할을 합니다.',
  },
  {
    title: '05. 모유 분비 촉진',
    paragraph: '한의학에서는 ‘음허’ 상태를 개선하는 약재를 사용하여 모유 분비를 촉진합니다. 산후보약에 포함된 특정 약재는 유즙의 분비를 촉진하고, 산모가 충분한 모유를 수유할 수 있도록 돕습니다.',
  },
  {
    title: '06. 정신적 안정 및 스트레스 해소',
    paragraph: '출산 후 정신적인 피로나 스트레스가 높아질 수 있습니다. 산후보약은 정신적, 정서적 균형을 맞추기 위한 약재를 포함하여 산모의 불안감이나 우울감을 완화하고, 신경을 안정시키는 효과를 제공합니다.',
  },
];

export const mobileDiseaseList = [
  {
    category: "산후보약",
    images: ["/images/medicine/woman/image1.svg", "/responsive/medicine/woman/1-1-1.svg"],
    components: [MobileThesesSwiper],  // MobileThesesSwiper 한 번만 포함
  },
  {
    category: "임신준비",
    images: ["/responsive/medicine/woman/1-2-1.svg", "/responsive/medicine/woman/1-2-2.png", "/responsive/medicine/woman/1-2-3.svg", "/responsive/medicine/woman/1-2-4.png",
      "/responsive/medicine/woman/1-2-5.svg", "/responsive/medicine/woman/1-2-6.png", "/responsive/medicine/woman/1-2-7.svg", "/responsive/medicine/woman/1-2-8.svg"
    ],
    components: [MobileThesesSwiper],  // MobileThesesSwiper 한 번만 포함
  },
];

