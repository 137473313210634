import { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Thumbs } from 'swiper/modules';
import CustomPagination from '../../../components/CustomPagination';

export interface MobileThesesSwiperProps {
  selectedCategory: number;
  mobileDiseaseList: DiseaseCategory[];
}

interface DiseaseCategory {
  category: string;
  images: string[];
  components: React.ComponentType<MobileThesesSwiperProps>[];
}

const slidesLength = 6;

const MobileThesesSwiper = ({ selectedCategory, mobileDiseaseList }: MobileThesesSwiperProps) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);
  const paginationRef = useRef(null);

  const pagination = {
    el: paginationRef.current,
    clickable: true,
    renderBullet: (index: number, className: string) => {
      return '<span class="' + className + '">' + (index + 1) + '</span>';
    },
  };

  const selectedCategoryData = mobileDiseaseList[selectedCategory];
  if (!selectedCategoryData) {
    return <div>카테고리가 없습니다.</div>;
  }

  const isPostpartum = selectedCategoryData?.category === "산후보약";
  const isPregnancyPrep = selectedCategoryData?.category === "임신준비";

  return (
    <div>
      {/* 한 개의 Swiper만 렌더링 */}
      <Swiper
        slidesPerView={'auto'}
        spaceBetween={20}
        loop={true}
        className="swiper-class"
        style={{
          maxWidth: '400px',
          marginTop: '-40px'
        }}
        modules={[Thumbs, Pagination]}
        onSwiper={setThumbsSwiper}
        pagination={pagination}
      >
        {Array.from({ length: slidesLength }).map((_, index) => {
          let imageSrc = "";

          // 산후보약 이미지 경로 (2-1, 2-2 형식)
          if (isPostpartum) {
            imageSrc = `/responsive/medicine/woman/effects/${index + 1}.svg`; // 산후보약 이미지 경로
          }
          // 임신준비 이미지 경로 (1, 2, 3 형식)
          else if (isPregnancyPrep) {
            imageSrc = `/responsive/medicine/woman/effects/2-${index + 1}.svg`; // 임신준비 이미지 경로
          }

          return (
            <SwiperSlide key={index}>
              <img
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                }}
                src={imageSrc}
                alt={`Slide ${index}`}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <CustomPagination ref={paginationRef} />
    </div>
  );
};

export default MobileThesesSwiper;
