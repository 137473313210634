import Swiper from 'swiper';
import Card from '../../../components/Card';
import { HorizontalDivider } from '../../../components/Divider';
import ImageDescriptionBox from '../../../components/ImageDescriptionBox';
import PageTitle from '../../../components/PageTitle';
import { Namsan, NotoSerif, Pretendard } from '../../../components/Typography';
import {
  GyeongokgoContainer,
  GyeongokgoSection,
  GyeongokgoList,
  ImageContainer,
  FullWidthGyeongokgoSection,
  GyeongokgoEffectParagraph,
  GyeongokgoDifferenceListItem,
  GyeongokgoMobileList,
  IngredientCard
} from './styles';
import GyeongokgoSwiper from './GyeongokgoSwiper';
import { Helmet } from 'react-helmet';
import useWindowSize from '../../../hooks/useWindowSize';
import { MobileContainer, MobileSection } from '../../../components/common';
import { effectsList, mobileImageList, suggestionsList } from './data';
import MobileThesesSwiper from './MobileThesesSwiper';
import { motion } from 'framer-motion';

const MedicineGyeongokgoPage = () => {
  const { width } = useWindowSize();
  if ((width || window.innerWidth) < 1080) {
    return (
      <>
        <Helmet>
          <title>에스본한의원 | 경옥고</title>
        </Helmet>
        <PageTitle title="경옥고" bgImage="medicine" />

        <MobileContainer>
          <MobileSection
            style={{
              padding: '0',
            }}
          >
            {mobileImageList[0].arr.map((image) => (
              <img
                style={{
                  width: '100%',
                  padding: '0 16px',
                }}
                key={image}
                src={`/responsive/medicine/gyeongokgo/${image}`}
                alt=""
              />
            ))}
            <MobileThesesSwiper />
          </MobileSection>
          <MobileSection>
            {mobileImageList[1].arr.map((image) => (
              <img
                style={{
                  width: '100%',
                }}
                key={image}
                src={`/responsive/medicine/gyeongokgo/${image}`}
                alt=""
              />
            ))}
          </MobileSection>
          <MobileSection>
            {mobileImageList[2].arr.map((image) => (
              <img
                style={{
                  width: '100%',
                  marginBottom: '40px',
                }}
                key={image}
                src={`/responsive/medicine/gyeongokgo/${image}`}
                alt=""
              />
            ))}
            <GyeongokgoMobileList
            // style={{
            //   gap: '10px',
            // }}
            >
              {Array.from({ length: 6 }).map((_, index) => (
                <li key={index}>
                  <img
                    style={{
                      width: '100%',
                    }}
                    src={`/responsive/medicine/gyeongokgo/list/${index + 1}-1.png`}
                    alt=""
                  />
                  <img
                    style={{
                      width: '100%',
                    }}
                    src={`/responsive/medicine/gyeongokgo/list/${index + 1}-2.svg`}
                    alt=""
                  />
                </li>
              ))}
            </GyeongokgoMobileList>
          </MobileSection>
          <MobileSection>
            {mobileImageList[3].arr.map((image) => (
              <img
                style={{
                  width: '100%',
                  marginTop: '40px',
                }}
                key={image}
                src={`/responsive/medicine/gyeongokgo/${image}`}
                alt=""
              />
            ))}
          </MobileSection>
        </MobileContainer>
      </>
    );
  }
  return (
    <>
      <Helmet>
        <title>에스본한의원 | 경옥고</title>
      </Helmet>
      <PageTitle title="경옥고" bgImage="medicine" />
      <GyeongokgoContainer>
        <GyeongokgoSection>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              title="365일 척추관절 중점진료 에스본한의원"
              paragraph={
                <>
                  논문으로 입증된
                  <br />
                  경옥고의 다양한 효능
                </>
              }
              position="center"
              containerStyle={{
                marginBottom: '50px',
              }}
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <ImageContainer>
              <GyeongokgoSwiper />
            </ImageContainer>
          </motion.div>
          <GyeongokgoList
            style={{
              marginTop: '60px',
            }}
          >
            {effectsList.map((effect) => (
              <GyeongokgoDifferenceListItem key={effect.title}>
                <NotoSerif
                  style={{
                    color: '#D2A780',
                    fontWeight: 500,
                  }}
                >
                  {effect.title}
                </NotoSerif>
                <Namsan
                  size="small"
                  style={{
                    fontWeight: 300,
                    color: '#A9A9A9',
                  }}
                >
                  {effect.source}
                </Namsan>
                <HorizontalDivider
                  style={{
                    width: '45px',
                    height: '2px',
                    alignSelf: 'center',
                    backgroundColor: '#D2A780',
                    margin: '36px 0',
                  }}
                />
                <GyeongokgoEffectParagraph>
                  <Namsan
                    as="p"
                    style={{
                      lineHeight: '33px',
                      color: '#d9d9d9',
                      letterSpacing: '-0.03em',
                    }}
                  >
                    {effect.paragraph}
                  </Namsan>
                </GyeongokgoEffectParagraph>
              </GyeongokgoDifferenceListItem>
            ))}
          </GyeongokgoList>
        </GyeongokgoSection>
        <FullWidthGyeongokgoSection
          style={{
            width: '100%',
            paddingTop: '200px',
            paddingBottom: '200px',
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <ImageDescriptionBox
              containerStyle={{
                maxWidth: '1180px',
                margin: '0 auto',
                paddingLeft: '100px',
                paddingRight: '100px',
                justifyContent: 'space-between',
              }}
              imageStyle={{
                maxWidth: '350px',
                maxHeight: '280px',
                aspectRatio: '350 / 280',
              }}
              src="/images/medicine/gyeongokgo/image3.png"
              descriptionStyle={{
                display: 'flex',
                flexDirection: 'column',
              }}
              description={
                <>
                  <Card
                    title="365일 척추관절 중점진료 에스본한의원"
                    paragraph={'무병장수의 명약 경옥고'}
                    paragraphStyle={{
                      color: '#333333'
                    }}
                    containerStyle={{
                      marginBottom: '40px',
                    }}
                  />
                  <Namsan
                    style={{
                      color: '#555555',
                      lineHeight: '33px',
                    }}
                  >
                    경옥고는 원기를 북돋아 노화를 지연시키고, 허약한 몸을 회복시켜<br />
                    활력을 되찾게 합니다. 정신을 맑게 하고 오장을 튼튼하게 하며,<br />
                    하루 두세 번 섭취로 뛰어난 효능을 느낄 수 있습니다.
                  </Namsan>
                </>
              }
            />
          </motion.div>
        </FullWidthGyeongokgoSection>
        <GyeongokgoSection>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              title="365일 척추관절 중점진료 에스본한의원"
              paragraph="이런 분들에게 권해드립니다"
              containerStyle={{
                marginBottom: '50px',
              }}
              position="center"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <GyeongokgoList>
              {suggestionsList.map((suggestion) => (
                <li key={suggestion.title}>
                  <img
                    style={{
                      width: '100%',
                    }}
                    src={suggestion.image}
                    alt=""
                  />
                  <div
                    style={{
                      margin: '0 auto',
                      display: 'flex',
                      flexDirection: 'column',
                      textAlign: 'center',
                      maxWidth: '270px',
                      paddingTop: '39px',
                      paddingBottom: '39px',
                    }}
                  >
                    <NotoSerif
                      style={{
                        color: '#D2A780',
                        fontWeight: 500,
                      }}
                    >
                      {suggestion.title}
                    </NotoSerif>
                    <HorizontalDivider
                      style={{
                        width: '45px',
                        height: '2px',
                        alignSelf: 'center',
                        backgroundColor: '#D2A780',
                        margin: '36px 0',
                      }}
                    />
                    <Namsan
                      as="p"
                      style={{
                        lineHeight: '33px',
                        textAlign: 'center',
                      }}
                    >
                      {suggestion.description}
                    </Namsan>
                  </div>
                </li>
              ))}
            </GyeongokgoList>
          </motion.div>
        </GyeongokgoSection>
        <GyeongokgoSection>
            <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                }}
                transition={{
                  ease: 'easeInOut',
                  duration: 1.3,
                  y: {
                    duration: 0.3,
                  },
                }}
                viewport={{
                  once: true,
                }}
              >
                <Card
                  title="365일 척추관절 중점진료 에스본한의원"
                  paragraph="100% 한약재로만 조제합니다"
                  containerStyle={{
                    marginBottom: '30px',
                  }}
                  position="center"
                />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '100px' }}>
                  <Namsan style={{ fontFamily: 'Pretendard', fontSize: '20px', lineHeight: '28px', letterSpacing: '-0.02em', textAlign: 'center', color: '#d9d9d9' }}>
                    기타 첨가물 없이 오로지 한약재
                    <br />
                    만으로 처방하는 진짜 경옥고
                  </Namsan>
                </div>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                }}
                transition={{
                  ease: 'easeInOut',
                  duration: 1.3,
                  delay: 0.3,
                  y: {
                    duration: 0.3,
                  },
                }}
                viewport={{
                  once: true,
                }}
              >
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '20px' }}>
                  <IngredientCard
                    imageSrc="/images/medicine/gyeongokgo/base1.png"
                    title="인삼"
                    description="원기보충, 소화기능개선, 진액보충 활력 증진"
                  />
                  <IngredientCard
                    imageSrc="/images/medicine/gyeongokgo/base2.png"
                    title="복령"
                    description="면역력 강화 및 노폐물 배출, 정서적 안정, 수면 개선"
                  />
                  <IngredientCard
                    imageSrc="/images/medicine/gyeongokgo/base3.png"
                    title="지황"
                    description="체액과 혈을 보충, 간과 신장을 도움, 노화 방지"
                  />
                  <IngredientCard
                    imageSrc="/images/medicine/gyeongokgo/base4.png"
                    title="꿀"
                    description="소화기능 개선, 풍부한 영양소 공급, 기운을 도움"
                  />
                </div>
          </motion.div>
        </GyeongokgoSection>
      </GyeongokgoContainer>
    </>
  );
};

export default MedicineGyeongokgoPage;
