import { motion } from 'framer-motion';
import Card from '../../../components/Card';
import { Namsan } from '../../../components/Typography';
import {
    CustomizedSection,
  GongjindanDifferenceList,
} from './styles';

interface CheckItemProps {
    title: string;
    description: string;
  }
  
  const CheckItem: React.FC<CheckItemProps> = ({ title, description }) => (
    <div style={{ width: '100%', display: 'flex', marginBottom: '20px', alignItems: 'flex-start' }}>
      <img 
        style={{ width: '25px', marginRight: '10px', marginTop: '5px'}} 
        src="/images/medicine/woman/icon_check.svg"
        alt="체크" 
      />
      <div 
        style={{ 
          flex: 1, 
          display: 'flex', 
          flexDirection: 'column', 
          fontFamily: 'Pretendard', 
          lineHeight: '33px', 
          letterSpacing: '-0.02em', 
          color: '#d9d9d9' 
        }}
      >
        <b style={{ fontSize: '22px', fontWeight: 400 }}>{title}</b>
        <p style={{ fontSize: '20px', fontWeight: 300 }}>
        <span dangerouslySetInnerHTML={{ __html: description }} />
      </p>
      </div>
    </div>
  );

const GongjindanSection = () => {
    return (
      <CustomizedSection
        style={{
          gap: '100px',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          marginBottom: '200px'
        }}
      >
        <motion.div
          style={{
            flexShrink: 0,
            position: 'sticky',
            top: '160px',
          }}
          initial={{ opacity: 0, y: 50 }}
          whileInView={{
            opacity: 1,
            y: 0,
          }}
          transition={{
            ease: 'easeInOut',
            duration: 1.3,
            y: {
              duration: 0.3,
            },
          }}
          viewport={{
            once: true,
          }}
        >
          <div
            style={{
              position: 'sticky',
              top: '160px',
            }}
          >
            <Card
              title="에스본한의원 차별점"
              paragraph={
                <>
                  올바른 산후조리,
                  <br />
                  단계별 치료가 필요합니다.
                </>
              }
              containerStyle={{
                marginBottom: '30px',
              }}
            />
          </div>
        </motion.div>
  
        <motion.div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '100px',
          }}
          initial={{ opacity: 0, y: 50 }}
          whileInView={{
            opacity: 1,
            y: 0,
          }}
          transition={{
            ease: 'easeInOut',
            duration: 1.3,
            delay: 0.3,
            y: {
              duration: 0.3,
            },
          }}
        >
          <div
            style={{
              maxWidth: '680px',
            }}
          >
            <img
              style={{
                width: '100%',
              }}
              src="/images/medicine/woman/type/image1.png"
              alt=""
            />
            <Card
              paragraphStyle={{
                marginTop: '0',
                color: '#D2A780',
                fontSize: '28px',
                fontWeight: 300,
                lineHeight: '45px',
              }}
              paragraph="1단계 (출산 직후 ~ 산후 1개월)"
              containerStyle={{
                marginTop: '50px',
                marginBottom: '30px',
              }}
            />
            <CheckItem
                title="자궁회복치료"
                description="분만 과정에서 소진된 체력을 보충하고, 잔류 어혈과 노폐물 배출을<br> 촉진합니다."
            />    
          </div>
  
          <div
            style={{
              maxWidth: '680px',
            }}
          >
            <img
              style={{
                width: '100%',
              }}
              src="/images/medicine/woman/type/image2.png"
              alt=""
            />
            <Card
              paragraph="2단계 (산후 1개월 ~ 산후 2개월)"
              paragraphStyle={{
                marginTop: '0',
                color: '#D2A780',
                fontSize: '28px',
                fontWeight: 300,
                lineHeight: '45px',
              }}
              containerStyle={{
                marginTop: '50px',
                marginBottom: '30px',
              }}
            />
            <CheckItem
                title="산후 보양 치료"
                description="출산 과정에서 허약해진 산모의 체력을 보출하고, 출산 이후 육아에<br> 적응하는 과정에서 겪는 스트레스 관리 및 산욕기 잔여 증상을 해소합니다."
            />  
            <CheckItem
                title="주의사항"
                description="산후조리를 잘못할 경우, 이후 산후풍이 생기는 원인이 될 수 있습니다.<br> 산욕기 기간 동안 약화된 근육과 관절을 보강하여 산후풍을 미리<br> 예방하여야 합니다."
            />  
          </div>

          <div
            style={{
              maxWidth: '680px',
            }}
          >
            <img
              style={{
                width: '100%',
              }}
              src="/images/medicine/woman/type/image3.png"
              alt=""
            />
            <Card
              paragraph="3단계 (산후 2개월 ~ 산후 100일)"
              paragraphStyle={{
                marginTop: '0',
                color: '#D2A780',
                fontSize: '28px',
                fontWeight: 300,
                lineHeight: '45px',
              }}
              containerStyle={{
                marginTop: '50px',
                marginBottom: '30px',
              }}
            />
            <CheckItem
                title="체력 강화"
                description="신진대사와 노폐물 배출을 원활하게 하고, 산후 부종과 비만을 예방하여<br> 건강한 육아를 돕습니다."
            />   
            <CheckItem
                title="체중 감량 치료"
                description="체계적인 과정을 통해 부종과 체지방을 보다 건강하게 줄입니다. 육아와<br> 일상복귀를 위한 기력 회복 과정이 동반되어야 합니다."
            />  
          </div>
        </motion.div>
      </CustomizedSection>
    );
  };
  

export default GongjindanSection;

