import styled from 'styled-components';
import List from '../../../components/List';
import { Namsan } from '../../../components/Typography';

interface CircleProps {
  size: string;
  variant?: 'contained' | 'outlined';
}

interface FullWidthSectionProps {
  color?: 'light' | 'dark';
}

export const CustomizedContainer = styled.div`
  padding-top: 200px;
  padding-bottom: 200px;
  display: flex;
  flex-direction: column;
  gap: 200px;
`;

export const CustomizedSection = styled.section`
  max-width: 1180px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DiagnosisList = styled.ul`
  max-width: 1024px;
  width: 100%;
  display: grid;
  grid-gap: 56px;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 50px;
  & li {
  }
`;


export const GyeongokgoList = styled(List)`
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 40px;
  & li {
    flex-direction: column;
    align-items: start;
    border: 1px solid #5d5d5d;
    min-height : 413px;
  }
`;

export const GyeongokgoDifferenceList = styled.ol`
  list-style: decimal;
  margin-inline-start: 1rem;
  & li::marker {
    color: #d9d9d9;
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
  }
`;

export const GyeongokgoEffectParagraph = styled.div`
  overflow: hidden;

  ${Namsan} {
    transition: all 1s;
    transform: translateY(-100px);
    opacity: 0;
  }
`;

export const GyeongokgoDifferenceListItem = styled.li`
  padding: 37px 16px 27px;

  &:hover {
    & ${GyeongokgoEffectParagraph} ${Namsan} {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

export const GyeongokgoMobileList = styled(List)`
  grid-column-gap: 10px;
  grid-row-gap: 40px;
  & li {
    flex-direction: column;
  }
`;

export const GongjindanDifferenceList = styled.ol`
  list-style: decimal;
  margin-inline-start: 1rem;
  & li::marker {
    color: #d9d9d9;
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
  }
`;
